export enum LogLevel {
    'TRACE',
    'DEBUG',
    'INFO',
    'WARN',
    'ERROR',
    'FATAL',
}

export enum LogColors {
    'grey' = 'color: #878686;',
    'green' = 'color: #3dbf15;',
    'blue' = 'color: #3094e6;',
    'yellow' = 'color: #c7c220;',
    'orange' = 'color: #d47c24;',
    'red' = 'color: #ff2626;',
    'unset' = 'color: unset;',
}

export enum LogLevelColorMap {
    'TRACE' = LogColors.grey,
    'DEBUG' = LogColors.blue,
    'INFO' = LogColors.green,
    'WARN' = LogColors.yellow,
    'ERROR' = LogColors.orange,
    'FATAL' = LogColors.red,
}

// @ts-ignore
const version = import.meta.env.APP_VERSION;

export class Logger {
    // @ts-ignore
    static logLevel: LogLevel = import.meta.env.DEV ? LogLevel.DEBUG : LogLevel.INFO;

    static log(level: LogLevel, msg: string, ...attrs: unknown[]): void {
        if (level >= this.logLevel && !!window.console) {
            attrs.unshift(LogLevelColorMap[level], LogColors.unset);

            switch (level) {
                case LogLevel.TRACE:
                    window.console.debug('%cTRACE:%c ' + msg, ...attrs);
                    break;
                case LogLevel.DEBUG:
                    window.console.debug('%cDEBUG:%c ' + msg, ...attrs);
                    break;
                case LogLevel.INFO:
                    window.console.info('%cINFO:%c ' + msg, ...attrs);
                    break;
                case LogLevel.WARN:
                    window.console.warn('%cWARNING:%c ' + msg, ...attrs);
                    break;
                case LogLevel.ERROR:
                    window.console.error('%cERROR:%c ' + msg, ...attrs);
                    break;
                case LogLevel.FATAL:
                    window.console.error('%cFATAL:%c ' + msg, ...attrs);
                    break;
            }
        }
    }

    static table(
        level: LogLevel,
        header: string,
        tabularData: unknown[]|object,
        properties?: string[],
    ): void {
        if (level >= this.logLevel && !!window.console) {
            window.console.group('%c%s:', LogLevelColorMap[LogLevel[level]], header);
            window.console.table(tabularData, properties);
            window.console.groupEnd();
        }
    }

    static information(level: LogLevel): void {
        Logger.table(level, 'Information', {
            // @ts-ignore
            'Current environment': import.meta.env.MODE,
            'Log level':           LogLevel[Logger.logLevel],
            'Application version': version,
            'App Locale':          window.appLocale,
            'Shop Shortcode':      window.shopShortcode,
            'Shop ID':             window.shopId,
            'Base URL':            window.baseUrl,
        });
    }
}

export default Logger;
